import React from "react";
import {
    SkeletonBar,
    SkeletonFormField,
    SkeletonParaField
} from "./style";

export function SkeletonForm(props) {
    return (
        <>
            <SkeletonFormField>
                <SkeletonBar style={{ height: props.labelHeight, width: props.labelWidth, marginBottom: props.mb }}></SkeletonBar>
                <SkeletonBar style={{ height: props.inputHeight, width: props.inputWidth, }}></SkeletonBar>
            </SkeletonFormField>
        </>
    )
}
export function SkeletonText(props) {
    return (
        <>
            <SkeletonParaField>
                <SkeletonBar style={{ height: props.height, width: props.width, marginBottom: props.mb }}></SkeletonBar>
                <SkeletonBar style={{ height: props.height, width: "80%", marginBottom: props.mb }}></SkeletonBar>
                <SkeletonBar style={{ height: props.height, width: "60%", marginBottom: props.mb }}></SkeletonBar>
            </SkeletonParaField>
        </>
    )
}

