import React from "react";
import styled, { space, device, themeGet } from "@styled";

export const FooterWrap = styled.footer`
  .container {
    ${device.xxlarge} {
      max-width: 1570px;
    }
  }
`;

export const FooterTop = styled.div`
  padding-block-start: 40px;
  padding-block-end: 40px;
  ${device.medium} {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }
  ${device.large} {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }
  h3 {
    border-left: 5px solid #f54812;
    padding-left: 20px;
    font-size: 25px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 50px;
  }
  h6 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 30px;
    color: ${themeGet("colors.primary")};
    position: relative;
    ${device.large} {
      margin-left: 0;
    }
    span {
      color: ${themeGet("colors.secondary")};
      font-weight: bold;
      position: absolute;
      right: calc(100% + 5px);
      top: -40%;
    }
  }
`;

export const FooterBottom = styled.div`
  padding: 25px 0;
  border-top: 1px solid #d4d4d4;
  a {
    display: block;
    ${device.medium} {
      display: inline-block;
    }
    &:first-child {
      ${device.medium} {
        padding: 0 10px;
        border: solid #ddd;
        border-width: 0 1px;
        margin: 0 10px;
      }
    }
  }
`;

export const FooterWidget = styled(
  ({ mt, mb, ml, mr, pt, pb, pl, pr, ...rest }) => <div {...rest} />
)`
  ${space};
`;

export const LogoWidget = styled.figure`
  margin-block-end: 30px;
  p {
    margin-top: 20px;
  }
  a {
    color: ${themeGet("colors.primary")};
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const TextWidget = styled.div`
  a,
  p {
    i {
      color: ${themeGet("colors.primary")};
    }
  }
`;

export const FooterWidgetList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${device.small} {
    flex-direction: row;
  }
  ${device.medium} {
    padding-left: 20px;
  }
  li {
    flex: 1 1 50%;
    margin-bottom: 10px;
  }
`;
