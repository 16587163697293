import styled from "@styled";

export const SkeletonBar = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    background-color: #DDDBDD;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
        animation: shimmer 2s infinite;
      }    
    @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
    }
`;
export const SkeletonFormField = styled.div`
    margin-bottom: 10px
`;
export const SkeletonParaField = styled.div`
    margin-bottom: 10px
`;