import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import cn from "clsx";
import { Container, Row, Col } from "@ui/wrapper";
import Social, { SocialLink } from "@ui/social";
import { SocialType } from "@utils/types";
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom,
} from "./style";
const Heading = React.lazy(() => import('@ui/heading'));
const Text = React.lazy(() => import('@ui/text'));
const Anchor = React.lazy(() => import('@ui/anchor'));

const Footer = ({ data }) => {
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <FooterWrap>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} md={12}>
              <FooterWidget mb={["31px", null, null, 0]}>
                <LogoWidget>
                  <StaticImage
                    src="../../../assets/images/logo/andovar-logo.png"
                    alt="logo"
                  />
                  <Text>
                    We enable brands to maximize efficiency and quality of
                    localization to deliver locally relevant brand experiences
                    that drive global growth...
                  </Text>
                  <Anchor path="/about-us" color="textColor" hoverstyle="2">
                    READ MORE...
                  </Anchor>
                </LogoWidget>
                {data?.socials && (
                  <Social
                    space="16px"
                    tooltip={true}
                    shape="rounded"
                    variant="outlined"
                  >
                    {data.socials.map((social ,index) => (
                      <SocialLink
                        key={index}
                        path={social.link}
                        title={social.title}
                        ariaLabel={social.ariaLabel}
                      >
                        <i className={cn(social.icon, "link-icon")}></i>
                      </SocialLink>
                    ))}
                  </Social>
                )}
              </FooterWidget>
            </Col>
            <Col lg={5} md={6}>
              <FooterWidget mb={["31px", null, null, 0]}>
                <Heading as="h3" mt="-3px" mb="20px">
                  Quick links
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/about-us"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      About
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/solutions/translation-localization-services"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      What we localize
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/solutions"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Language Solutions
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/translation-management"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Translation Management
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/industries"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Industries
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Key Benefits
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/our-work"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Our Work
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/language-guides"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Languages
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="https://blog.andovar.com/"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      Blog
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      target="_blank"
                      path="/"
                      color="text"
                      $hover={{ layout: 2 }}
                    >
                      FAQ
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={3} md={6}>
              <FooterWidget mb={["27px", null, 0]}>
                <Heading as="h3" mt="-3px" mb="20px">
                  Contact Us
                </Heading>
                {data?.contact && (
                  <TextWidget>
                    <Heading as="h6">
                      <span>HQ</span>Singapore
                    </Heading>
                    {data?.contact?.address && (
                      <Text
                        mb="10px"
                        dangerouslySetInnerHTML={{
                          __html: data.contact.address,
                        }}
                      ></Text>
                    )}
                    {data.contact?.phone && (
                      <Text mb="10px">
                        <i className="me-2 fal fa-solid fa-phone"></i>
                        <Anchor
                          path={`tel:${data.contact.phone}`}
                          color="heading"
                          $hover={{ layout: 2 }}
                        >
                          {data.contact.phone}
                        </Anchor>
                      </Text>
                    )}
                  </TextWidget>
                )}
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row alignItems="center">
            <Col md={12} textAlign={["center", null, "center"]}>
              {data?.copyright && (
                <Text pb={["15px", 0]}>
                  &copy; {new Date().getFullYear()}{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.copyright,
                    }}
                  />
                </Text>
              )}
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
    </React.Suspense>
)
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
    copyright: PropTypes.string,
    contact: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
};

export default Footer;
